import dk from "@norsktest/dkdj";

export class NewsletterSignupWidget extends dk.Widget {
    constructor(...args) {
        super({
            signup_url: '/newsletter/',
        }, ...args);
    }

    construct() {
        this.submit_btn = this.widget('button[type="submit"]');
        this.init_submit_btn_text = this.submit_btn.text();
        this.newsletter_input = this.widget('input[name="newsletter_id"');
        this.email_input = this.widget('.vEmailInput');
        this.form_feedback = this.widget('.form-feedback');
    }

    handlers() {
        this.widget().on('keypress', () => this.reset_form_feedback());
        this.widget().on('submit', e => this.submit_handler(e));
    }

    submit_handler(evt) {
        evt.preventDefault();

        if (this.email_input[0].dataset.valid === 'false') {
            this.display_error('Ugyldig e-postadresse.');
            return false;
        }

        this.start_busy();

        this.signup();
        return false;
    }

    reset_submit_btn() {
        this.email_input.prop('readonly', false);
        this.submit_btn.text(this.init_submit_btn_text);
    }

    display_error(msg) {
        this.form_feedback.addClass('errordescr');
        this.form_feedback.text(msg);
    }

    signup() {
        dk.json({
            url: this.signup_url,
            data: {
                email: this.email_input.val(),
                newsletter_id: this.newsletter_input.val(),
            },
            success: data => {
                const msg = data.message;
                if (!data.ok) {
                    this.display_error(msg);
                    this.reset_submit_btn();
                } else {
                    this.end_busy(msg);
                }
            }
        });
    }

    start_busy() {
        this.email_input.prop('readonly', true);
        const spinner = `<div class="lds-ring">
                          <div></div><div></div><div></div><div></div>
                        </div>`;
        this.submit_btn.html(spinner);
    }

    end_busy(msg) {
        const success_icon = `<dk-icon value="check:fw" style="color:#CCE5E6"></dk-icon>`;
        this.submit_btn.html(success_icon);
        this.submit_btn.prop('disabled', true);
        this.form_feedback.text(msg);
    }


    reset_form_feedback() {
        this.form_feedback.removeClass('errordescr');
        this.form_feedback.text('');
    }

}
